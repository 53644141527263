// This modules uses data attributes on DOM elements via livequery, current available:
// data-pxscroll: float that sets the vertical speed, can be negative
define('modules/animations/parallaxscroll/js/base.js',["app/config","jquery","app/util","skrollr"], function(config,$,util,skrollr) {
    return {
        name: 'parallaxscroll',
        selectors: false,
        remoteData: [],

        config: {
            scrollSpeed: -250,
            widthBreakpoint: 768
        },

        routes: [{
            route: '.*',
            fn: 'resetSkrollr',
            extra: true,
            delay: 50
        }],

        initialize: function() {

            var scrollSpeed = (config.scrollSpeed || -200);

            $('body').livequery('*[data-pxscroll]',$.proxy(function(el) {
                var layerValue = $(el).attr('data-pxscroll'),
                    modeType = $(el).attr('data-pxscrollmode') || 'element',
                    scrollAmount = (layerValue*scrollSpeed),
                    startOffset = (modeType == 'background' ? (0-(scrollAmount/2)) : 0),
                    addTransform = (typeof $(el).attr('data-pxtransform') == "undefined" ? '' : $(el).attr('data-pxtransform')+';');
                $(el).attr((modeType == 'viewport' ? 'data-start' : 'data-bottom-top'), 'transform:translateY('+(startOffset)+'px)'+addTransform);
                $(el).attr((modeType == 'viewport' ? 'data-end' : 'data-top-bottom'), 'transform:translateY('+(scrollAmount+startOffset)+'px)'+addTransform);
                this.resetSkrollr();
            },this));

            $(window).smartresize($.proxy(this.resetSkrollr,this));

            setTimeout(function(){
                $(window).trigger('smartresize');
            },2000);
        },

        setSkrollr: function() {
            skrollr.init({
                forceHeight: false,
                smoothScrollingDuration: 200, // 200
                mobileCheck: function () {
                    return false;
                }, // Uncomment mobileCheck for mobile debugging
                constants: {
                    vph: function() {
                        return $('html').height() || $('#form1').height();
                    },
                    mid: function() {
                        return $('html').height()/2 || $('#form1').height()/2;
                    },
                    35: function() {
                        return Math.round($('html').height()*0.35) || Math.round($('#form1').height()*0.35);
                    }
                }
            });
        },

        destroySkrollr: function() {
            if(skrollr.get()) {
                skrollr.get().destroy();
            }
            $('html').addClass('no-skrollr');
        },

        resetSkrollr: function() {
            if($(window).width() >= this.config.widthBreakpoint && $('html').hasClass('no-touch')) {
                if(skrollr.get()) {
                    skrollr.get().refresh();
                } else {
                    this.setSkrollr();
                }
            } else {
                this.destroySkrollr();
            }
        }
    }
});
