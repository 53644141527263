define('modules/footer/classic/js/base.js',["app/module","app/config","jquery","app/util","templates","waypoints"], function(module,config,$,util,templates) {
    return new module({
        name: 'footer',
        selectors: '.footerWrap',
        initialize: function () {
            // Render the template
            $('.footerWrap').append(templates.footer());
        }
    })
});
