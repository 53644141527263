define('modules/scoring/classic/js/base.js',["app/config", "jquery", "app/util", "app/module", "templates", "bootstrap/slider"], function (config, $, util, module, templates) {
    return new module({
        name: 'scoring',
        selectors: '.scoreSection',
        remoteData: [(config.urls.scoring)],

        config: {
            scoreQuestionWindow: false
        },


        initialize: function (data) {

            // TODO add a config?
            //$('.scoreSection').append(templates.scoringintro);

            var iKeyCount = 0;

            $.each(data, $.proxy(function (index, record) {
                $('.scoreSection').append($(templates.scoring($.extend(record, {
                    index: (index + 1),
                    total: data.length
                }))));

                $('.scoreSliderInput').last().slider();
                $('.scoreSliderInput').last().on('slideStart', function () {
                    $('body').trigger('animrefresh', $(this));
                });
                $('.scoreSliderInput').last().on('slide slideStop', function (event) {
                    var newDescription = record.items[Math.floor(event.value || $(this).val())].text,
                        selectedTick = $(this).parents('.scoreQuestion').find('.tickBottom').eq(Math.floor(event.value || $(this).val())),
                        slideLeft = $(this).parents('.scoreQuestion').find('.min-slider-handle')[0].style.left;
                    $('.tickBottom').removeClass('highlight');
                    selectedTick.addClass('highlight');
                    $(this).parents('.scoreQuestion').find('.scoreDescription span').text(newDescription);
                    $(this).parents('.scoreQuestion').find('.scoreValue .valfollow').text(event.value || $(this).val());
                    $(this).parents('.scoreQuestion').find('.scoreValue span').css('left', slideLeft);

                    if ($("body.backend").length && event.type == "slideStop") {
                        TEAMAPP.publishChange($(this).parents('.scoreQuestion').find('.scoreValue .valfollow').text(), eId, 'AF2DE7FC-EA0C-4F86-912D-6AA326D930A0', 'appOnBlurScoring', '85C1D4BA-0C43-4872-84E7-15E7BF0989A3', record.pw_key);
                        var statusBefore = $(this).parents('.scoreQuestion').find('.valfollow').attr('status');
                        $(this).parents('.scoreQuestion').find('.valfollow').attr('status', 'complete');
                        //$(this).parents('.scoreQuestion').find('.valfollow').css("color", "green");
                        if (statusBefore == "incomplete") {
                            TEAMAPP.ScoreComplete = parseInt($(".pw_scoring_tasks_done").text());
                            TEAMAPP.ScoreComplete = TEAMAPP.ScoreComplete + 1
                            $(".pw_scoring_tasks_done").text(TEAMAPP.ScoreComplete);
                        }
                        TEAMAPP.scoreTextUpdate();
                    }

                });

                $(".txtComment").last().blur(function (event) {
                    if ($("body.backend").length) {
                        var statusBefore = $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status');

                        iKeyCount = 0;
                        TEAMAPP.publishChange(event.value || $(this).val(), eId, 'AF2DE7FC-EA0C-4F86-912D-6AA326D930A0', 'appOnBlurScoring', '1FF363BA-2FF9-4E0E-A85C-39FCC7AFE404', record.pw_key);

                        var value = $(this).val();

                        if (value.length == 0) {
                            $(this).closest(".commentsText").find(".spnCharsTyped").html(0);
                            return;
                        }

                        var regex = /\s+/gi;
                        var wordCount = value.trim().replace(regex, ' ').split(' ').length;

                        //if ((event.value || $(this).val()).length >= 50) {
                        if (wordCount >= 10) {
                            //$(this).parents('.scoreQuestion').find('.scoreCommentBtn').css('color', "green");
                            $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status', 'complete');
                            $(this).parents('.scoreQuestion').find('.spnCharsTyped').attr('status', 'complete');
                            if (statusBefore == "incomplete") {
                                TEAMAPP.ScoreComplete = parseInt($(".pw_scoring_tasks_done").text());
                                TEAMAPP.ScoreComplete = TEAMAPP.ScoreComplete + 1
                                $(".pw_scoring_tasks_done").text(TEAMAPP.ScoreComplete);
                            }

                        }
                        else {
                            //$(this).parents('.scoreQuestion').find('.scoreCommentBtn').css('color', "red");
                            $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status', 'incomplete');
                            $(this).parents('.scoreQuestion').find('.spnCharsTyped').attr('status', 'incomplete');
                            if (statusBefore == "complete") {
                                TEAMAPP.ScoreComplete = parseInt($(".pw_scoring_tasks_done").text());
                                TEAMAPP.ScoreComplete = TEAMAPP.ScoreComplete - 1
                                $(".pw_scoring_tasks_done").text(TEAMAPP.ScoreComplete);
                            }
                        }
                        TEAMAPP.scoreTextUpdate();
                    }
                });


                var autosavetimer;

                $(".txtComment").last().keyup(function (event) {
                    // TODO event.value?
                    if ($("body.backend").length) {
                        var statusBefore = $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status');

                        if (iKeyCount >= 10) {
                            iKeyCount = 0;
                            TEAMAPP.publishChange(event.value || $(this).val(), eId, 'AF2DE7FC-EA0C-4F86-912D-6AA326D930A0', 'appOnBlurScoring', '1FF363BA-2FF9-4E0E-A85C-39FCC7AFE404', record.pw_key);
                        }
                        else {
                            iKeyCount++;
                        }


                        // auto save timer
                        var thisTextCmt = $(this);
                        //clear any running timeout on key up
                        clearTimeout(autosavetimer);
                        autosavetimer = setTimeout(function() {
                          TEAMAPP.publishChange(event.value || thisTextCmt.val(), eId, 'AF2DE7FC-EA0C-4F86-912D-6AA326D930A0', 'appOnBlurScoring', '1FF363BA-2FF9-4E0E-A85C-39FCC7AFE404', record.pw_key);
                        }, 750);

                        var value = $(this).val();
                        var regex = /\s+/gi;
                        var wordCount = value.trim().replace(regex, ' ').split(' ').length;

                        //if ((event.value || $(this).val()).length >= 50) {
                        if (wordCount >= 10) {
                            //$(this).parents('.scoreQuestion').find('.scoreCommentBtn').css('color', "green");
                            $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status', 'complete');
                            $(this).parents('.scoreQuestion').find('.spnCharsTyped').attr('status', 'complete');
                            if (statusBefore == "incomplete") {
                                TEAMAPP.ScoreComplete = parseInt($(".pw_scoring_tasks_done").text());
                                TEAMAPP.ScoreComplete = TEAMAPP.ScoreComplete + 1
                                $(".pw_scoring_tasks_done").text(TEAMAPP.ScoreComplete);
                            }

                        }
                        else {
                            //$(this).parents('.scoreQuestion').find('.scoreCommentBtn').css('color', "red");
                            $(this).parents('.scoreQuestion').find('.scoreCommentBtn').attr('status', 'incomplete');
                            $(this).parents('.scoreQuestion').find('.spnCharsTyped').attr('status', 'incomplete');
                            if (value.length == 0) {
                                $(this).closest(".commentsText").find(".spnCharsTyped").html(0);
                            }
                            if (statusBefore == "complete") {
                                TEAMAPP.ScoreComplete = parseInt($(".pw_scoring_tasks_done").text());
                                TEAMAPP.ScoreComplete = TEAMAPP.ScoreComplete - 1
                                $(".pw_scoring_tasks_done").text(TEAMAPP.ScoreComplete);
                            }
                        }
                        TEAMAPP.scoreTextUpdate();
                    }
                });
                $('.scoreSliderInput').last().trigger('slide');
            }));

            if (this.getConfig().scoreQuestionWindow) {
                $('.scoreSection .wrapTarget').not(':first').not(':last').wrapAll( '<div class="window-wrapper"><div class="innerScroll"></div></div>' );
                $('.scoreSection .window-wrapper').prepend('<div class="topCurve inverted"></div>').append('<div class="bottomCurve inverted"></div>');
            }
            // TODO delete possibly
            //$('.scoreSection').append('<div class="lastCard section pushSection register scoring layout-auto-height angleTopLeft" data-bgimage="endcard"></div>');
            //$('.lastCard.scoring').append(templates.lastcard);


            // Comments
            $('.scoreSection .scoreCommentBtn').click(function (e) {
                e.preventDefault();
                if ($(this).hasClass('opened')) {
                    $(this).parents('.scoreQuestion').find('.icon-icon-close').trigger('click');
                    return;
                }

                $(this).parents('.scoreQuestion').find('.help-question').hide();
                $(this).parents('.scoreQuestion').find('.helpText').hide();
                $(this).parents('.scoreQuestion').find('.commentsText').show('fade', {
                    duration: 400
                });
                $(this).parents('.scoreQuestion').find('.icon-icon-close').show();
                //$(this).text('Close Comments').addClass('opened');
                $(this).addClass('opened');
                if ($('html').hasClass('touch') && ($(window).width() < 1366)) {
                    $('.backend-scoring').addClass('locked');
                }
            });
            // Help
            $('.scoreSection .help-question').click(function (e) {
                e.preventDefault();
                $(this).hide();
                $(this).parents('.scoreQuestion').find('.commentsText').hide();
                $(this).parents('.scoreQuestion').find('.helpText').toggle('fade', {
                    duration: 400
                });
                $(this).parents('.scoreQuestion').find('.icon-icon-close').show();
                $(this).parents('.scoreQuestion').find('.scoreCommentBtn').hide();
            });
            // Close icon
            $('.scoreSection .icon-icon-close').click(function (e) {
                e.preventDefault();

                $(this).hide();

                if ($('html').hasClass('touch') && ($(window).width() < 1366)) {
                    $('.backend-scoring').removeClass('locked');
                    $(this).parents('.scoreQuestion').find('.commentsText, .helpText').hide();
                    $(this).parents('.scoreQuestion').find('.scoreCommentBtn').show().removeClass('opened');
                    $(".txtComment").each(function (i) {
                        var commentLen = $(this).val().length;

                        if (commentLen == 0) {
                            $(this).parents('.rpCommentCol').find('.scoreCommentBtn').text('Enter Feedback');
                        }
                        else if (commentLen > 0) {
                            $(this).parents('.rpCommentCol').find('.scoreCommentBtn').text('Edit Feedback');
                        }

                    });
                }

                $(this).parents('.scoreQuestion').find('.help-question').show();
                $(this).parents('.scoreQuestion').find('.scoreCommentBtn').show().removeClass('opened');

            }).hide(); // Initially hidden

            // Navigation
            $('.scoreSection .scoreNav .next, .scoreSection .scoreNav .prev').click(function (e) {
                e.preventDefault();
                var parentEl = $(this).parents('.scoreQuestion');
                if ($(this).hasClass('prev')) {
                    if ($(parentEl).prev('.scoreQuestion').length > 0) {
                        var shownEl = $(parentEl).prev('.scoreQuestion').show();
                        $(parentEl).hide();
                    }
                } else {
                    if ($(parentEl).next('.scoreQuestion').length > 0) {
                        var shownEl = $(parentEl).next('.scoreQuestion').show();
                        $(parentEl).hide();
                    }
                }
                // Close anything open on nav by triggering the shown elements close button
                $(shownEl).find('.icon-icon-close').trigger('click');
            });

            // Mobile initial states & responsive sizing handling
            $(window).smartresize(function (e) {
                if ($(window).width() >= 768) {
                    $('.scoreSection .scoreQuestion').show();
                } else {
                    //$('.scoreSection section').hide();
                    //$('.scoreSection section').first().show();
                    //$('.scoreSection').height(window.innerHeight);
                    // Tick dividers
                    $('.scoreSection .scoreQuestion').each(function () {
                        /*var splitValue = $(this).find('.tickTop').eq(1).offset().left-$(this).find('.tickTop').eq(0).offset().left;
                         $(this).find('.tickTop').each(function(){
                         $(this).css('margin-left',splitValue/2);
                         });*/ // TODO - THIS ONLY IMPACTS MOBILE
                        // This was causing issues and couldn't determine why it was necessary
                        //$(this).find('.tickTop').last().remove();
                    });
                    // Scrolling ticks
                    $('.scoreSection .scoreQuestion').each(function () {
                        var tickCount = $(this).find('.tickBottom').length;
                        if (tickCount > 4) {
                            var totalTickWidth = 70 * tickCount,
                                sliderTicksEl = $(this).find('.sliderTicks');
                            $(sliderTicksEl).width('auto').css({
                                'margin-left': '50px',
                                'left': '0px'
                            });
                            $(sliderTicksEl).find('.tickBottom').each(function (index, tick) {
                                $(tick).css({
                                    'min-width': '70px',
                                    'left': (70 * index)
                                });
                            });
                            $(sliderTicksEl).find('.tickTop').each(function (index, tick) {
                                $(tick).css({
                                    'left': (70 * index) + 35
                                    //'left': (70 * index)
                                });
                            });
                            // Set slider listener and set initial value position
                            $(this).find('.scoreSliderInput').on('slide', function (event) {
                                var totalShift = ((tickCount + 1) * 70) - window.innerWidth;
                                var shiftIncrement = (totalShift * ((event.value) / tickCount));
                                $(sliderTicksEl).css('left', -(shiftIncrement));
                            });
                            var totalShift = ((tickCount + 1) * 70) - window.innerWidth;
                            var shiftIncrement = (totalShift * (($(this).find('.scoreSliderInput').slider('getValue')) / tickCount));
                            $(sliderTicksEl).css('left', -(shiftIncrement));
                        }
                    });
                }
            }).trigger('resize'); // Immediately trigger

            var cmtext = $(".topHidden").html();

            if ($("body.backend").length) {
                TEAMAPP.updateAllScores();
            }

            //if($(window).width() >= 768) {
            //	$( '<div class="container"><section class="text-center introSection scoringHeader mobileHide" data-title="">'+ cmtext +'</section></div>' ).insertBefore($('.scoreSection section:eq(2)'));
            //}

            $('body').livequery('.scoreQuestion:visible', function () {
                setTimeout(function () {
                    $('body').trigger('animrefresh', $(this));
                }, 2500);
            });
        }
    })
});

