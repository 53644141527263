define('modules/faqs/expander/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'faqs.expander',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],
        
        config: {
            
        },

        routes: [{
            route: 'home',
            extra: true,
            delay: 5000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        },{
            route: 'home',
            fn: 'closeDetails',
            extra: true
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
        },

        onRender: function() {
        	this.clickHandlers(this.data);
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this));  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

		methods: {
			renderTemplates: function (data) {
				$.each(data, function (i, r) {
					data[i].total = data.length; // TODO - Not necessary, we can access parent context length directly in handlebars tpl via dot notation
				});
				$(this.getSelectors()).append(templates.faqsexpander({
					records: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
				}));
			},
			
			clickHandlers: function (data) {

                var expanderOpening = false;

				// TODO - We need to refactor the scoping on expanderGrid class, as this is used in the app feature
				$('.expanderGrid').find('.inner-col').on('click',function(e){

				    e.preventDefault();

                    expanderOpening = true;

					// Set the active attribute on clicked column
					$('.expanderGrid').find('.inner-col').attr('data-active',false);
					$(this).attr('data-active',true);

					var qaCardsLength = $('.expanderGrid .swiper-slide:not(.swiper-slide-duplicate) .qa-card:not(.duplicate)').length;
					var activeCardNum = $(this).closest('.qa-card').attr('data-card-num');
						activeCardNum = parseInt(activeCardNum);

					// Update and move description row
					var parentRow = $(this).parents('.row').first();

					var h3cont = $(this).find('h3').html();
					$('.expanderGrid').find('.detailRow .expanderContent').html('<h3></h3>');
					$('.expanderGrid').find('.detailRow .expanderContent').find('h3').html(h3cont);
					$('.expanderGrid').find('.detailRow .expanderContent').append($(this).find('.innerSwap').html());

					$('.expanderGrid').find('.detailRow .expander-count').find('.current-count').text(activeCardNum);
					$('.expanderGrid').find('.detailRow .expander-count').find('.total-count').text(qaCardsLength);

					var swiperInstance = $('.swiper-container')[0].swiper;
					swiperInstance.stopAutoplay();

					// possible mobile handling - unused at the moment
					// if($(this).width()*2 > $('.expanderGrid').width()) {
					// 	$('.expanderGrid').find('.detailRow').insertAfter($(this));
					// } else {
					// 	$('.expanderGrid').find('.detailRow').insertAfter(parentRow);
					// }

					$('.expanderGrid').find('.detailRow').insertAfter(parentRow);

					var animationDelayCount;
					var xInitial = 0;
					$('.expanderGrid').find('.swiper-slide-visible .qa-card').each(function(i) {
						var self = $(this);
						var x = xInitial;

						if (self.closest('.swiper-slide.swiper-slide-visible').css('opacity') == 1) {

							if (!self.closest('.swiper-slide').hasClass('.swiper-slide-active')) {
								var x = x / 8;

								self.css('animation-delay', x + 's');

								animationDelayCount = x;

								xInitial = xInitial + 1;

								return animationDelayCount;
							}

							return animationDelayCount;
						}
					});

					animationDelayCount = (animationDelayCount * 1000); // TODO - AnimationDelayCount has no fallback value, see above if statement

					setTimeout(function() {
						$('.expanderGrid').find('.swiper-slide-active').addClass('transform-delayed');
						$('.expanderGrid').find('.detailRow').addClass('visible');
						$('.expanderGrid').find('.swiper-container-outer').addClass('details-opened');
						expanderOpening = false;
					}, animationDelayCount);

					// TODO better mobile handling
					var bodyWidth = $('body').width();
					if (bodyWidth < 768) {
						$('body').addClass('locked');
					};
				});

				//nav clicks reworked
				$('.expanderGrid').find('.expanderNav .nav-btn').on('click', function() {
					var self = $(this);
					var qaCardsLength = $('.expanderGrid .swiper-slide:not(.swiper-slide-duplicate) .qa-card:not(.duplicate)').length;
					var activeIndexEl = $('.expanderGrid').find('.inner-col[data-active="true"]');
					var activeQaCard = activeIndexEl.closest('.qa-card');

					var swiper = $('.swiper-container')[0].swiper;

					if (self.hasClass('next')) {

						activeIndexEl.attr('data-active', false);

						var nextCard;

						if (activeQaCard.is(':last-child')) {
							var nextSlide = activeQaCard.closest('.swiper-slide').next('.swiper-slide');
							if (nextSlide.hasClass('swiper-slide-duplicate')) {
								nextCard = $('.swiper-slide:not(.swiper-slide-duplicate):first').find('.qa-card:first-child');
								var firstSlide = $('.swiper-slide:not(.swiper-slide-duplicate)[data-swiper-slide-index="0"]');
								var firstSlideIndex = $('.swiper-slide').index(firstSlide);
								swiper.slideTo(firstSlideIndex);
							} else {
								nextCard = activeQaCard.closest('.swiper-slide').next().find('.qa-card:first-child');
								swiper.slideNext();
							}
						} else {
							nextCard = activeQaCard.next('.qa-card');
						};

						nextCard.find('.inner-col').attr('data-active', true).trigger('click');

					} else if (self.hasClass('prev')) {

						activeIndexEl.attr('data-active', false);

						var prevCard;

						if (activeQaCard.is(':first-child')) {
							var prevSlide = activeQaCard.closest('.swiper-slide').prev('.swiper-slide');
							if (prevSlide.hasClass('swiper-slide-duplicate')) {
								prevCard = $('.swiper-slide:not(.swiper-slide-duplicate):last').find('.qa-card:last-child');
								var lastSlide = $('.swiper-slide-duplicate[data-swiper-slide-index="0"]:last').prev('.swiper-slide');
								var lastSlideIndex = $('.swiper-slide').index(lastSlide);
								swiper.slideTo(lastSlideIndex);
							} else {
								prevCard = activeQaCard.closest('.swiper-slide').prev().find('.qa-card:last-child');
								swiper.slidePrev();
							}
						} else {
							prevCard = activeQaCard.prev('.qa-card');
						};

						prevCard.find('.inner-col').attr('data-active', true).trigger('click');
					};
				});
				
				// close click
				$(this.getSelectors()).find('.expanderNav span.close').on('click', $.proxy(function(e){
				    e.preventDefault();
				    if(!expanderOpening) {
                        $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
                        // $('.detailRow').fadeOut(300);
                        $('.detailRow').removeClass('visible');
                        $('.expanderGrid').find('.swiper-slide-active').removeClass('transform-delayed');
                        $('.expanderGrid').find('.swiper-container-outer').removeClass('details-opened');

                        var swiperInstance = $('.swiper-container')[0].swiper;
                        swiperInstance.startAutoplay();
                        $('.detailRow .video-id iframe').attr('src','');
                        $('.expanderGrid').find('.inner-col').attr('data-active', false);
                        $('body').removeClass('locked');
                        $('.navbarWrap').removeClass('hidden');
                        $('body').removeClass('locked');
                    }
				},this));
			},

            initSwiper: function() {

			    if(this.swiperInstance) {
			        return;
                }

                //TODO - apply comments to explain the functions

                var qaCardsInitialLength = $('.qa-card').let;

                if (!((qaCardsInitialLength % 3) === 0)) {

                    var numberOfCopies = 2;

                    for (i = 0; i<numberOfCopies; i++) {

                        $('.qa-card:not(.duplicate)').each(function(n) {
                            $(this).clone(true).addClass('duplicate').appendTo('.swiper-wrapper');
                        });
                    };
                };

                var qaCards = $('.qa-card');

                for (var i = 0; i < qaCards.length; i+=3) {
                    qaCards.slice(i, i+3).wrapAll('<div class="swiper-slide"></div>');
                };

                var slidesCount = $('.swiper-container .swiper-slide').length;

                this.swiperInstance = new Swiper('.swiper-container', {
                    autoplay: 5000,
                    autoplayDisableOnInteraction: false,
                    initialSlide: 1,
                    setWrapperSize: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    slideToClickedSlide: true,
                    watchSlidesVisibility: true,
                    //next lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    loop: true,
                    loopedSlides: slidesCount,
                    breakpoints: {
                    	992: {
                    		initialSlide: 0,
                    		slidesPerView: 1,
                    		centeredSlides: true,
                    		loop: true,
                    		loopedSlides: null,
							prevButton: ".swiper-navigate-prev",
							nextButton: ".swiper-navigate-next"
						}
                    },
                    onAutoplay: function(sw) {
                        if(sw.slides.length == sw.activeIndex+1) {
                            sw.slideTo(1);
                        }
                        var autoPlayStatus = sw.startAutoplay(); // TODO - Is this necessary?
                    },
                });

                // $('.swiper-container').find('.swiper-slide:last-of-type').addClass('preventSlideTo');

                $('.faqWrap').css('opacity', '1');
            },
            closeDetails: function() {
            	if ($('.swiper-container-outer').hasClass('details-opened')) { 
            		$(this.getSelectors()).find('.expanderNav span.close').trigger('click');
            	}
                
            },
		}
	});
});

