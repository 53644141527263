define('modules/news/promogrid/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.mobile.custom"], function(module,config,$,util,templates) {
    return new module({
        name: 'news',
        selectors: '.newsPage',
        remoteData: [config.urls.news],

        routes: [{
            route: 'news/(.*)',
            fn: function(hash) {
                $(this.getSelectors())
                .find('.newsListingEntryRow[data-news="'+hash.split('/')[1]+'"],.newsGridPromoBox[data-news="'+hash.split('/')[1]+'"]')
                .trigger('click');
            },
            extra: true,
            delay: 2500
        },{
            route: 'news',
            fn: 'closeDetails',
            extra: true
        }],

        config: {
            promoCount: 3,
            useCarousel: false,
            showPromoItemsInListing: false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            this.data = data;
            var shouldUseCarousel = this.getConfig().useCarousel || $(window).width() < 768;
            var promoNewsItems = data.slice(0, this.getConfig().promoCount);

            var regularNewsItems = (this.getConfig().showPromoItemsInListing ? data : data.slice(this.getConfig().promoCount));

            var categories = util.unique($.map(regularNewsItems, function(record){
                return record.category;
            }));
            categories.unshift('All Categories');
            
            this.$container.find('.newsContainer').html(templates.newspage({
                regularNewsItems: regularNewsItems,
                promoNewsItems: promoNewsItems,
                categories: $.map(categories,function(name){
                   return {name: name};
                }),
                usecarousel: shouldUseCarousel
            }));

            if(data.length < this.getConfig().promoCount) {
                this.$container.find('.newsListing').remove();
            } else if(categories.length > 2) {
                this.$container.find('.newsListingTabs .tab').on('click', $.proxy(function (e) {
                    e.preventDefault();
                    $(e.currentTarget).siblings().removeClass('active');
                    $(e.currentTarget).addClass('active');
                    this.switchPostListing($(e.currentTarget).text());
                }, this));
                this.$container.find('.newsListingTabs .tab').first().trigger('click');
            } else {
                this.$container.find('.newsListingTabs').remove();
            }
            this.$container.find('.newsListingEntryRow,.newsGridPromoBox,.newsGridCarouselItem').on('click', $.proxy(function (e) {
                e.preventDefault();
                $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                this.showNewsPost($(e.currentTarget).attr('data-news'));
            }, this));
            
            this.flexCheck();

            if(shouldUseCarousel){
                // init carousel
                this.$container.find('.carousel.slide')
                .carousel({
                    interval: 7500,
                    pause: null
                }).swipeleft(function() {  
                    $(this).carousel('next');  
                }).swiperight(function() {  
                    $(this).carousel('prev');  
                });
            }

            // Resize handler to keep the right side aligned
            $(window).smartresize($.proxy(this.alignRightScroll,this));
        },

        methods: {

            switchPostListing: function(category) {
                if(category == 'All Categories') {
                    this.$container.find('.newsListingEntryRow').show();
                } else {
                    this.$container.find('.newsListingEntryRow').hide();
                    this.$container.find('.newsListingEntryRow[data-category="' + category + '"]').show();
                }
            },

            renderPosts: function() {
            	// TODO accounting for animations
                this.$container.find('.newsDetailContainer').html(templates.sharedcard({
                    records: $.map(this.data,function(rec){
                        return {
                        	image: rec.image,
                            id: rec.id,
                            h3: rec.title,
                            h4: rec.description,
                            span: rec.date,
                            category: rec.category,
                            content:  rec.content
                        }
                    })
                }));
                $('body').addClass('locked').trigger('pagenavupdate');
                $('.navbarWrap').addClass('hidden');
                this.$container.find('.tplWrapper').addClass('open');
                this.$container.find('.newsDetailContainer .icon-close').on('click',$.proxy(function(e){
                    e.preventDefault();
                    this.closeDetails();
                },this));
            },

            showNewsPost: function(newsId) {
                this.renderPosts();
                $('.newsContainer').find('.tplWrapper').css('overflow','scroll');
                $('.newsContainer').find('.tplWrapper').scrollTop($(this.$container.find('*[data-pagenav][data-id="'+newsId+'"]')).position().top+1);
                setTimeout($.proxy(function(){
                    $('body').trigger('pagenavupdate');
                    $('.newsContainer').find('.tplWrapper').css('overflow','hidden');
                },this),5);
            },

            closeDetails: function() {
                if(this.isReady()) {
                    this.$container.find('.tplWrapper').removeClass('open');
                    $('body').removeClass('locked');
                    setTimeout($.proxy(function(){
                        this.$container.find('.newsDetailContainer').empty();
                    },this),1000);
                    $('.navbarWrap').removeClass('hidden');
                }
            },
            
            flexCheck: function() {
            	// work around for flex box
            	if ($('.newsGridFlex .newsGridPromoBox').length == 2) {
            		$('.newsGridFlex').addClass('flex-two')
            	}
            },

            alignRightScroll: function() {
                if($('body').hasClass('newsDetailOpen')) {
                    var elIndex = parseInt($('.newsContainer .sideNav .currentCount').text())-1;
                    var activeCard = $('.newsContainer .cardWrapper .cardWrap').eq(elIndex);
                    if(activeCard.length > 0) {
                        $('.newsContainer').find('.tplWrapper').css('overflow','scroll');
                        $('.newsContainer').find('.tplWrapper').scrollTop($(activeCard).position().top+1);
                        setTimeout($.proxy(function(){
                            $('.newsContainer').find('.tplWrapper').css('overflow','hidden');
                        },this),30);
                    }
                }
            }
        }

    });
});
