define('modules/navigationmap/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "skrollr"], function (module, config, $, util, templates, skrollr) {
    return new module({
        name: 'navigationmap',
        selectors: '.navMapWrap',

        remoteData: [config.urls.navigationmap],

        routes: [{
            route: '.*',
            fn: function (hash) {
                this.closeFunction(hash, !this.getConfig().disableTranslate);
            },
            delay: 10,
            priority: 10
        }],

        config: {
            lastRoute: '#home', // Default
            noBackground: false,
            pageContentSelectors: '.singlePageWrap, .backendPageContent',
            zoomto: true,
            hideScene: false,
            zoomDuration: 1000, // TODO - Use of this config below isn't quite right in all instances, refine accordingly
            disableTranslate: false
        },

        initialize: function (data) {

            // Private vars
            this.zoomed = false;
            this.animating = false;
            this.fromNavMap = false;

            // Apply default data
            this.data = (data || this.data);

            // Render template
            $('.navMapWrap').append(templates.navmapgrid({
                pages: this.data,
                config: config
            })).addClass('closed');

            var me = this;

            // Refresh config for newsCount
            config.refresh();
        },

        onRender: function () {
            this.clickHandlers();
            if ($('.navMapWrap').hasClass('noBg')) {
                this.noBackground = true;
            }
            var w = $(window).width();

            // mobile add events -- TODO - Refactor this and make it more dynamic
            if (w <= 480) {
                var parent = $('.extraEl').parents('.box');
                $('<div class="box" data-route="events"><div class="boxWrap"><span class="title">Events</span></div></div>').insertAfter(parent);
                //$( ".gridWrap .row div:nth-child(2)" ).append( "<span> - 2nd!</span>" );​​
            }

            // Initial page routing, or default - triggers a click to zoom the map
            // Defaults to the homepage
            if (!this.noBackground) {
                this.getConfig().lastRoute = (location.hash || this.getConfig().lastRoute);
                var navBox = $('.navMapWrap .box[data-route="' + this.getConfig().lastRoute.split('/')[0].substr(1) + '"]'),
                    page = $('.pageContainer[data-route="' + this.getConfig().lastRoute.split('/')[0].substr(1) + '"]').addClass('active');
                if (page.length < 1 || navBox.length < 1) {
                    this.setInitialBox($('.navMapWrap .box').eq(0)); // For now default to top left
                } else if (navBox) {
                    this.setInitialBox(navBox);
                }
            }
        },

        methods: {

            clickHandlers: function () {
                // Main click handling for navmap boxes
                $(this.getSelectors).find('.navMapWrap .box').on('click', $.proxy(function (e) {
                    // Prevent default and stop if animation is already in progress
                    e.preventDefault();
                    var el = $(e.delegateTarget);

                    if (this.animating) {
                        return;
                    }

                    // Check if this has subnav, if so take different action
                    var route = $(el).attr('data-route'),
                        subnav = this.getSubnav(route);


                    if (subnav) {
                        if ($(e.target).attr('data-route')) {
                            // Simply update the route and continue through the rest of the method
                            route = $(e.target).attr('data-route');
                        }
                        else {
                            // Show the subnav options and return early
                            $(el).find('.subNavList').show();
                            setTimeout($.proxy(function () {
                                //$(this).find('.boxWrap > span').addClass('fadeUpOut');
                                //$(this).find('.subNavList span').addClass('fadeUpIn');

                                //I adjusted this a little bit as the title
                                //isn't supposed to disappear


                                //if (w >= 481) {
                                $(el).find('.boxWrap .title').addClass('up');
                                //if (w >= 481) {

                                $(el).find('.subNavList').addClass('fadeUpIn');
                                // }
                            }, this), 25);
                            return;
                        }
                    }

                    // If we are not clicking on a suvnav box, perform the animation to the correct position
                    $('.navMapWrap .grid').css({
                        '-webkit-transition-property': '-webkit-transform',
                        '-moz-transition-property': '-moz-transform',
                        '-ms-transition-property': '-ms-transform',
                        '-o-transition-property': '-o-transform',
                        'transition-property': 'transform'
                    });


                    if (!this.zoomed) {
                        this.zoomToBox($(el), $.proxy(function () {
                            if (this.zoomed) {
                                window.location.hash = route;
                            }
                            this.closeFunction(route, false);
                        }, this));
                    }
                    else {
                        this.showFullNavMap();
                    }


                }, this));

                // Opens the navmap
                $('.navTrigger').on('click', $.proxy(function (e) {
                    e.preventDefault();
                    this.openNavMap();
                    $('.gridWrap').addClass('open'); // TODO - needs a smoother transition
                }, this));

                // Click handler for the close button, which just relays a click to the navmap box using the current or default (home) route
                // If the "noBg" class is set on navMapWrap, instead things will just fade out
                $('.mapClose').on('click', $.proxy(function (e) {
                    if ($('.navMapWrap').hasClass('noBg')) {
                        $(this.getConfig().pageContentSelectors).show(); // TODO - Better split our backend logic for selectors/elements
                        $('.navMapWrap').addClass('closed');
                    } else {
                        var route = (location.hash.substr(1) ? location.hash.split('/')[0].substr(1) : 'home');
                        $('.navMapWrap .box[data-route="' + route + '"]').trigger('click');
                    }
                }, this));
            },

            setInitialBox: function (el) {
                this.animating = false;
                this.zoomed = true;
                var position = this.getBoxPosition($(el));
                $('.navMapWrap .grid').css({
                    'transform': 'scale(' + this.getNavMapScale() + ')',
                    '-webkit-transform': 'scale(' + this.getNavMapScale() + ')',
                    '-ms-transform': 'scale(' + this.getNavMapScale() + ')',
                    'transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-webkit-transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-ms-transform-origin': position[0] + 'px ' + position[1] + 'px'
                });
            },

            zoomToBox: function (el, callback) {
                callback = (callback || $.noop);
                this.animating = true;
                this.fromNavMap = true;
                $(el).addClass('zActive');
                var position = this.getBoxPosition($(el));
                $('.navMapWrap .grid').css({
                    'transform': 'scale(' + this.getNavMapScale() + ')',
                    '-webkit-transform': 'scale(' + this.getNavMapScale() + ')',
                    '-ms-transform': 'scale(' + this.getNavMapScale() + ')',
                    'transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-webkit-transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-ms-transform-origin': position[0] + 'px ' + position[1] + 'px'
                });
                setTimeout($.proxy(function () {
                    this.animating = false;
                    this.zoomed = true;
                    callback();
                }, this), this.getConfig().zoomDuration);
            },

            getNavMapScale: function () {

                if (this.getConfig().zoomto) {
                    return ($('.navMapWrap .grid').width() / $('.navMapWrap .box').width()) + 0.3;
                    if ($(window).width() <= 480) {
                        return 3;
                    }
                }
                else {
                    return 1;
                }
            },

            showFullNavMap: function () {
                $('.navMapWrap .grid').css({
                    'transform': 'scale(1)',
                    '-webkit-transform': 'scale(1)',
                    '-ms-transform': 'scale(1)'
                });
                setTimeout($.proxy(function () {
                    this.animating = false;
                    this.zoomed = false;
                }, this), this.getConfig().zoomDuration);

                // close modals
                $('.actionWrap.modal').removeClass('in');
                setTimeout(function () {
                    $('.actionWrap.modal').modal('hide');
                }, this.getConfig().zoomDuration);
            },

            // Gets the non-scaled position of the navBox
            // This is currently hardcoded and dependant on the gridWrap border width (9px)
            getBoxPosition: function (el) {
                var centerX = $(el).position().left + 9;
                if ($(el).index() == 1) {
                    centerX += ($(el).width() / 2) - 9;
                } else if ($(el).index() == 2) {
                    centerX += $(el).width() - 18;
                }
                var centerY = $(el).position().top + 9;
                if ($(el).parents('.row').index() == 1) {
                    centerY += ($(el).height() / 2 ) - 9;
                } else if ($(el).parents('.row').index() == 2) {
                    centerY += $(el).height() - 18;
                }
                // Override the centerX for mobile
                if ($(window).width() <= 480) {
                    centerX = ($(window).width() / 2);
                }
                return [centerX, centerY];
            },

            getSubnav: function (route) {
                var foundItem = null;
                $.each(this.data, function () {
                    if (route == this.route && this.children) {
                        foundItem = this;
                    }
                });
                return foundItem;
            },

            openNavMap: function () {
                if (this.noBackground) {
                    $(this.getConfig().pageContentSelectors).hide();
                    $('.navMapWrap').removeClass('closed');
                } else {
                    // TODO initial hooks for different page scenes
                    if (this.getConfig().hideScene) {
                        $('.zoom-el').addClass('transition-out').delay(1000).queue(function () {
                            $(this).fadeOut().addClass('hidden').dequeue();
                        });
                    }
                    $('.navbarWrap').addClass('closed navMapWrapOpen').removeClass('slideDownIn mobile-transition');
                    $('.navbarWrap .navbar-brand').addClass('logo-animate-closed');
                    $('.navbarWrap .navbar-brand-sub').removeClass('top').addClass('logo-animate-closed');
                    $('.footerWrap').hide();
                    this.lastRoute = $('.pageContainer.active').attr('data-route');
                    this.hidePage($.proxy(function () {
                        // TODO write a different config specific to pagecontainer
                        if (this.getConfig().zoomto) {
                            $(this.getConfig().pageContentSelectors).hide();
                        }
                        else {
                            $(this.getConfig().pageContentSelectors).addClass('navmap-open');
                        }
                        $('.navMapWrap').removeClass('closed');
                        var boxEl = $('.navMapWrap .box[data-route="' + this.lastRoute + '"]');
                        if (boxEl.length > 0) {
                            boxEl.trigger('click');
                        } else if ($('.navMapWrap .box.zActive').length > 0) {
                            $('.navMapWrap .box.zActive').trigger('click');
                        } else {
                            $('.navMapWrap .box').first().trigger('click');
                        }
                    }, this), 1050);
                    // nasa scene specific
                }
            },

            translateMapToBox: function (navBox, callback) {
                callback = (callback || $.noop);
                $('.footerWrap').hide();
                $('.navMapWrap .grid').css({
                    'transform': 'scale(1)',
                    '-webkit-transform': 'scale(1)',
                    '-ms-transform': 'scale(1)'
                });
                $('.navMapWrap .grid').css({
                    'transition-property': '-webkit-transform-origin'
                });
                var position = this.getBoxPosition($(navBox));
                $('.navMapWrap .grid').css({
                    'transform': 'scale(' + this.getNavMapScale() + ')',
                    '-webkit-transform': 'scale(' + this.getNavMapScale() + ')',
                    '-ms-transform': 'scale(' + this.getNavMapScale() + ')'
                });
                $('.navMapWrap .grid').css({
                    'transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-webkit-transform-origin': position[0] + 'px ' + position[1] + 'px',
                    '-ms-transform-origin': position[0] + 'px ' + position[1] + 'px'
                });
                setTimeout(function () {
                    callback();
                    $('.footerWrap').show();
                }, this.getConfig().zoomDuration);
            },

            closeFunction: function (route, translateBg) {
                if (this.noBackground) {
                    return;
                }
                route = (route && route.length > 0 ? route : this.getConfig().lastRoute.replace('#', ''));
                if (this.fromNavMap) {
                    setTimeout($.proxy(function () {

                        $('.navbarWrap').removeClass('closed navMapWrapOpen');
                        $('.navbarWrap .navbar-brand').removeClass('logo-animate-closed');

                        if (this.getConfig().zoomto) {
                            this.showPage(route);
                            $(this.getConfig().pageContentSelectors).show();
                            $('.footerWrap').show();
                            $('.navMapWrap').addClass('closed');
                        }
                        else {
                            $('.navMapWrap').addClass('closing');

                            setTimeout($.proxy(function () {
                                $(this.getConfig().pageContentSelectors).removeClass('navmap-open');
                                $('.footerWrap').show();
                                $('.navMapWrap').addClass('closed');
                                this.showPage(route);
                                $('.navMapWrap').removeClass('closing');
                            }, this), this.getConfig().zoomDuration);
                        }


                        $('body').trigger('pagenavupdate');


                        util.scrollBodyToTop(0);

                        this.fromNavMap = false;
                    }, this), this.getConfig().zoomDuration);
                }
                else if (translateBg) {
                    this.animating = true;
                    $('.navMapWrap').addClass('sliding');
                    $('.pageContainer.active').addClass('navMap-sliding');
                    var navBox = $('.navMapWrap .box[data-route="' + route + '"]');
                    if (!navBox || navBox.length < 1) {
                        navBox = $('.navMapWrap .box').eq(0);
                    }
                    this.hidePage($.proxy(function () {
                        this.translateMapToBox(navBox, $.proxy(function () {
                            this.showPage(route);
                        }, this));
                    }, this));
                }
                else {
                    this.hidePage($.proxy(function () {
                        this.showPage(route);
                    }, this));
                }

                // Reset any subnav animation states
                $('.navMapWrap .boxWrap > span').removeClass('up');
                $('.navMapWrap .subNavList').removeClass('fadeUpIn');
                $('.navMapWrap .subNavList').hide();

                // needs a smoother transition
                $('.gridWrap').removeClass('open');
                // TODO initial hooks for different page scenes
            },

            hidePage: function (callback) {
                callback = (callback || $.noop);
                $('.pageContainer.active').addClass('navMap-sliding');
                setTimeout(function () {
                    $('.pageContainer.active').hide().removeClass('active navMap-sliding');
                    callback();
                }, this.getConfig().zoomDuration);
            },

            showPage: function (route) {
                if (route == 'register' || route == 'login' || route == '/pw') {
                }
                else {
                    if ($('.pageContainer[data-route="' + route.split('/')[0] + '"]').length > 0) {
                        var page = $('.pageContainer[data-route="' + route.split('/')[0] + '"]');
                        page.addClass('active navMap-sliding');
                        if ($('.modalActionWrap .modal:visible').length < 1) {
                            // If a modal is visible, do not immediately show the page/animation - keep it hidden
                            // When the modal is closed, it will be shown again
                            page.show();
                        }
                        setTimeout($.proxy(function () {
                            page.removeClass('navMap-sliding');
                            $('.navMapWrap').removeClass('sliding');
                            if (this.getConfig().disableTranslate) {
                                $(this.getConfig().pageContentSelectors).removeClass('navmap-open');
                                $('.navbarWrap').removeClass('closed navMapWrapOpen mobile-transition slideUpOut');
                                $('.navbarWrap .navbar-brand').removeClass('logo-animate-closed');
                                $('.footerWrap').show();
                                $('.navMapWrap').addClass('closed');
                            }
                            this.animating = false;
                            $('body').trigger('pagenavupdate');

                            if (this.getConfig().hideScene) {
                                $('.zoom-el').removeClass('mid bottom');

                                setTimeout($.proxy(function () {
                                    $('.zoom-el').removeClass('hidden');
                                }, this), 500);

                                setTimeout($.proxy(function () {
                                    $('.zoom-el').removeClass('transition-out');
                                    if (skrollr.get()) { skrollr.get().refresh(); }
                                }, this), 1000);

                            } else {
                                if (skrollr.get()) { skrollr.get().refresh(); }
                            }


                        }, this), this.getConfig().zoomDuration);
                    } else {
                        window.location.hash = '404';
                        this.animating = false;
                    }
                }
            }
        }


    });
});
