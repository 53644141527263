define('modules/finalists/expander/js/base.js',['app/config', 'jquery', 'app/util', 'templates', 'skrollr', 'waypoints', 'jquery.mobile.custom'], function (config, $, util, templates, skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer',
        remoteData: [config.urls.finalists],
        data: [],

        config: {
            // previewOnly: true // False on MIT 2016 project
        },

        routes: [
            // {
            //           route: '(.*)',
            //           fn: function(hash) {
            //               this.closeAllOverlays(hash);
            //           },
            //           extra: true,
            //           delay: 3000
            //       },
            {
                route: 'finalists/(.*)',
                fn: function (hash) {
                    this.goToHash2(hash);
                },
                extra: true,
                delay: 2000
            }],
        initialize: function (data) {
            var initHtml;

            this.$container = $(this.selectors);
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.finalistOrginalData(this.data);

            // if(!this.config.previewOnly) {
            this.finalistTabHandler(this.data);
            $('body').trigger('pagenavupdate');
            // }

            // util.bootstrapNumberCarousel('.finalistsSection');

        },

        //     closeAllOverlays: function (hash) {

        //     	//remove body class
        //     	$('body').removeClass('finalistsBody');
        //     	//set bodyclass
        //         setTimeout(function(){
        //         	if ($('.pageContainer.active').hasClass('finalistsPageContainer')) {
        // 	$('body').addClass('finalistsBody');
        // }
        //         }, 1000);

        //     	var hasHash = hash.split('/')[1];

        //     	if (!hasHash) {
        //     		//$('.closeNav')[0].click();
        // $('.expanderNav i.close').trigger('tap');
        //     	}
        //     },

        goToHash2: function (hash) {
            var scrollToTeam = hash.split('/')[1];
            //console.log(scrollToTeam);

            $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

            var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
            if (finalistItem) {

                var innerCol = finalistItem.find('.inner-col');
                $(innerCol).trigger('tap');

            }
        },

        renderTemplates: function (data) {

            $.each(data, function (i, r) {
                data[i].total = data.length;
            });


            $(this.selectors).append(templates.finalists({
                steps: data
            }));

            $('.finalists-count .count').html(data.length);

            // nasa 2017 specific code
            this.$container.on('click', '.finalist-post', $.proxy(function (event) {

                var $target = $(event.target);

                if (!$target.hasClass("finalist-post")) {
                    $target = $target.parents(".finalist-post");
                }

                var innerSwap = $target.find('.innerSwap').html();

                this.$container.find('.openedFinalist').removeClass('openedFinalist');

                $target.addClass('openedFinalist');


                var index = $target.attr('data-id');

                $('.expander-counts .current-count').html(index);

                this.$container.find('.expanderContent').html(innerSwap).slideDown(200);


                if (!this.$container.find('expanderNav').hasClass('shown')) {
                    this.$container.find('.expanderNav').addClass('shown');
                }
                ;

                $('html, body').animate({
                    scrollTop: $(this.selectors).find('.expanderContent').offset().top
                }, 250, function(){
                    $(window).trigger('resize');
                });



            }, this));

            this.$container.find('.expanderNav .prev').on('click', $.proxy(function () {

                var currentActive = this.$container.find('.openedFinalist');

                if (currentActive.prev('.finalist-post').length) {
                    currentActive.prev('.finalist-post').trigger('click');
                } else {
                    $('.finalist-post:last-child').trigger('click');
                }
                ;
            }, this));

            this.$container.find('.expanderNav .next').on('click', $.proxy(function () {

                var currentActive = this.$container.find('.openedFinalist');

                if (currentActive.next('.finalist-post').length) {
                    currentActive.next('.finalist-post').trigger('click');
                } else {
                    $('.finalist-post:first-child').trigger('click');
                }
                ;
            }, this));

            this.$container.find('.expanderNav .close').on('click', $.proxy(function () {

                this.$container.find('.openedFinalist').removeClass('openedFinalist');
                this.$container.find('.expanderContent').slideUp(400, function () {
                    $(this).html('');
                });
                this.$container.find('.expanderNav').removeClass('shown');

                $('html, body').animate({
                    scrollTop: $(this.selectors).offset().top
                }, 500, function(){
                    $(window).trigger('resize');
                });

            }, this));

            // nav control on scroll
            /* $(window).on('scroll', function() {

               var scrollTop = $(window).scrollTop(),
                   elem = $('.expanderNav'),
                   elemHeight = elem.outerHeight(),
                   parent = $('.finalistsSection'),
                   parentHeight = parent.outerHeight(),
                   parentOffsetTop = parent.offset().top,
                   distance = (parentOffsetTop - scrollTop),
                   botDistance = (parentHeight + parentOffsetTop - scrollTop - elemHeight - 100);

               if (distance < 100) {
                   elem.addClass('abs-pos').css('top', 100);

                   if (botDistance < 40) {
                       elem.addClass('abs-bot').css('top', 'auto');
                   } else {
                       elem.removeClass('abs-bot');
                   };

               } else {
                   elem.removeClass('abs-pos');
               };
             })*/

        },

        finalistOrginalData: function (data) {
            initHtml = $('.finalists.eGrid').html();
        },

        finalistTabHandler: function (data) {

            // commented unnecessary code when you don't have categories

            // $('.finalist-cat').not('.finalist-cat.view-all').click(function () {

            //     $('.expanderNav i.close').trigger('tap');

            //     // show all at first
            //     if ($('.finalist-post.finalist-visible').length < data.length) {
            //         $('.finalists.eGrid').html(initHtml);
            //         $('.expanderNav').hide();
            //     }


            //     // remove and swap active class
            //     $('.finalist-cat').removeClass('active');
            //     $(this).addClass('active');

            //     var finalistCat = $(this).attr('data-catid');

            //     $('.finalist-post').not($('.finalist-post[data-catid="' + finalistCat + '"]')).removeClass('finalist-visible').fadeOut();

            //     $('.finalist-post[data-catid="' + finalistCat + '"]').addClass('finalist-visible');


            //     // sort finalist posts
            //     var $finalistPosts = $('.finalists.eGrid .finalist-post.finalist-visible');

            //     var finalistsNewOrder = $finalistPosts.sort(function (a, b) {

            //         var result = $(a).attr('data-id') > $(b).attr('data-id') ? 1 : -1;
            //         return result;

            //     });


            //     $('.finalists.eGrid .finalist-row').remove();

            //     $('.finalists.eGrid').prepend(finalistsNewOrder);

            //     $('.finalist-post.finalist-visible').hide();

            //     $('.finalist-post.finalist-visible').each(function (index) {
            //         //$(this).fadeIn(1000);
            //         $(this).delay(100 * index).fadeIn(500);
            //     });

            //     $('.finalists.eGrid').livequery('.finalist-post.finalist-visible', function () {

            //         // TODO rows are specific to MIT
            //         var w = $(window).width();

            //         //desktop
            //         if (w > 1024) {
            //             while (($children = $(':not(.row.finalist-row)>.finalist-post:lt(3)')).length) {
            //                 $children.wrapAll($('<div class="row finalist-row"></div>'));
            //             }
            //         }

            //         //tablet
            //         else if (w <= 1024) {
            //             while (($children = $(':not(.row.finalist-row)>.finalist-post:lt(2)')).length) {
            //                 $children.wrapAll($('<div class="row finalist-row"></div>'));
            //             }
            //         }

            //         //phone
            //         else if (w <= 768) {
            //             while (($children = $(':not(.row.finalist-row)>.finalist-post:lt(1)')).length) {
            //                 $children.wrapAll($('<div class="row finalist-row"></div>'));
            //             }
            //         }

            //         var finalistNewDataId = 1;

            //         $('.finalists.eGrid .finalist-post.finalist-visible').each(function () {
            //             $(this).attr('data-id', finalistNewDataId);
            //             $(this).find('.inner-col').attr('data-id', finalistNewDataId);
            //             finalistNewDataId++;
            //         });

            //         $('.expander-counts .total-count').html((finalistNewDataId - 1));
            //     });

            // });

            // $('.finalist-cat.view-all').click(function () {
            //     $('.finalist-cat').removeClass('active');
            //     $(this).addClass('active');

            //     $('.expanderNav i.close').trigger('tap');
            //     $('.finalists.eGrid').html(initHtml);

            //     $('.finalist-post.finalist-visible').hide();

            //     $('.finalist-post.finalist-visible').each(function (index) {
            //         //$(this).fadeIn(1000);
            //         $(this).delay(100 * index).fadeIn(500);
            //     });

            //     $('.expanderNav').hide();
            // });

        }
    }
});

