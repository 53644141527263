define('modules/lastcard/basic/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'lastcard',
        selectors: '.lastCard',
        remoteData: [],

        initialize: function (data) {
            $(this.selectors).append(templates.lastcard({
                config: config
            }));
        }
    }
});
